export const ruOrdersImport = {
  title: "Импорт заказов",
  result: {
    "title": "Создание заказов",
    "loading": "Создание {{orderCount}} заказов. \nПожалуйста, дождитесь окончания.",
    "fileName": "Загруженный файл:",
    "fileCount": "Количество заказов:",
    "deletedFileCount": "Количество удаленных заказов:",
    "uploadDateTime": "Дата и время загрузки:"
  },
  "table": {
    "columns": {
      "createDate": "Дата создания",
      "Sender": "Отправитель",
      "Receiver": "Получатель",
      "city": "Город",
      "addressString": "Адрес",
      "addressFromFile": "Адрес из файла",
      "phone": "Телефон",
      "addressComments": "Комментарии",
      "total": "Количество",
      "operation": "Действие",
      "route": "Маршрут",
      "cell": "Ячейка",
      "status": "Статус",
      "delete": "Удалить",
      "id": "№ заказа",
    },
    "emptyField": "Пожалуйста введите {{title}}!",
    "cityError": "Выберите вариант из списка или добавьте город, если он не был найден",
    "emptyCity": "Город не выбран",
    "newCity": "Добавить город",
    "ok": "Да",
    "confirmDelete": "Уверены, что хотите \n удалить доставку?",
    "confirmSave": "Уверены, что хотите сохранить доставку?",
    "cancel": "Нет"
  },
  "buttons": {
    "create": "Создать заказы",
    "cancel": "Отмена"
  },
  "confirm": "Подтвердите создание заказа",
  "cancel": "Отменить создание заказов",
  "total": {
    "successfullyLoaded": "Успешно загружено:",
    "of": "заказов из",
    "error": "Исправьте данные в таблице, чтобы продолжить создание заказов"
  },
  "forms": {
    "title": "Импортировать файл",
    "type": "Выберите назначение загружаемого файла:",
    "typeDropdown": "Выберите назначение",
    "next": "Далее",
    "load": "Загрузить",
    "back": "Назад",
    "save": "Сохранить",
    "upload": {
      "table": {
        "orderItemId": "Номер доставки",
        "thirdPartyTrackingNumber": "Трек-номер"
      },
      "body": {
        "drag": "Перетащите файл сюда или",
        "click": "нажмите для выбора",
        "formats": "Допустимые форматы:"
      },
      "error": {
        "format": "Неверный формат файла . Пожалуйста, загрузите файл в формате"
      }
    }
  },
}