import { RouteObject } from "react-router-dom"


import PrivateRoute from "../wrappers/PrivateRoute";
import SignInPage from "@/pages/signin"
import SignUpPage from "@/pages/signup"
import Home from "@/pages/home"
import OrderViewPage from "@/pages/order"
import ReportPage from "@/pages/report"
import Layout from "../../Layout"
import AuthProvider from "../providers/AuthProvider";
import TelegramOrderViewPage from "@/telegram/pages/order"
import TelegramProvider from "../providers/TelegramProvider"
import TelegramLayout from "@/telegram/TelegramLayout"
import TelegramOrderActionPage from "@/telegram/pages/order-action"
import CourierViewPage from "@/pages/courier"
import AuthorizedRoute from "../wrappers/AuthorizedRoute"
import { Roles } from "../constants/roles"
import OnboardViewPage from "@/pages/onboard";
import RequiresOnboardingRoute from "@/common/wrappers/RequiresOnboardingRoute";
import OrderCreatePage from "@/pages/order-create"
import StickerListPrintPage from "@/pages/order-print"
import OrderProvider from "@/common/providers/OrderProvider";
import OrderListPrintPage from "@/pages/order-list-print"
import ResourceProvider from "../providers/ResourceProvider"
import OrderNotePrintPage from "@/pages/order-note-print"
import OrderAttachmentViewPage from '@/pages/order-attachment/OrderAttachmentViewPage';
import OrderImportViewPage from '@/pages/order-import/OrderImportViewPage';
import TelegramOrderItemActionPage from "@/telegram/pages/order-item-action"
import ClientHandbookViewPage from '@/pages/client-handbook';
import ScannerView from '@/pages/scanner/ScannerView';
import PrintProvider from '@/common/providers/PrintProvider';

const routes: RouteObject[] = [
	{
		element: <AuthProvider />,
		children: [
			{
				element: <Layout />,
				children: [
					{
						element: <PrivateRoute />,
						children: [
							{
								element: <ResourceProvider />,
								children: [
									{
										element: <RequiresOnboardingRoute />,
										children: [
											{
												element: <OrderProvider />,
												children: [
													{
														element: <PrintProvider />,
														children: [
															{ path: '/orders', element: <OrderViewPage /> },
														]
													},
													{ path: '/order-attachments/:orderId', element: <OrderAttachmentViewPage /> },
												],
											},
											{ path: '/', element: <Home /> },
										],
									},
									{
										element: <AuthorizedRoute allowedFor={[Roles.Manager, Roles.Admin]} />,
										children: [
											{ element: <OrderProvider />,
												children: [
													{ path: '/orders-import', element: <OrderImportViewPage /> },
												]
											},
											{ path: '/couriers', element: <CourierViewPage /> },
											{ path: '/client-handbook', element: <ClientHandbookViewPage /> },
											{ path: '/reports', element: <ReportPage /> },
											{ path: '/scanner', element: <ScannerView /> },
										],
									},
									{
										element: <AuthorizedRoute allowedFor={[Roles.Client]} />,
										children: [
											{ path: '/orders-create', element: <OrderCreatePage /> },
											{ path: '/onboard', element: <OnboardViewPage /> },
										],
									},
								],
							},
						],
					},
				],
			},
			{
				element: <PrintProvider/>,
				children: [
					{
						element: <PrivateRoute />,
						children: [
							{
								element: <AuthorizedRoute allowedFor={[Roles.Manager, Roles.Admin]} />,
								children: [
									{ path: '/orders-list-print', element: <OrderListPrintPage /> },
									{ path: '/order-stickers-print', element: <StickerListPrintPage /> },
								],
							},
							{ path: '/orders-notes-print', element: <OrderNotePrintPage /> }
						]
					},
				],
			},
			{ path: '/signin', element: <SignInPage /> },
			{ path: '/just-send-signup', element: <SignUpPage /> },
		],
	},
	{
		element: <TelegramProvider />,
		children: [
			{
				element: <TelegramLayout />,
				children: [
					{ path: '/telegram/courier', element: <TelegramOrderViewPage /> },
					{ path: '/telegram/courier/order-item/view', element: <TelegramOrderItemActionPage /> },
					{ path: '/telegram/courier/order/view', element: <TelegramOrderActionPage /> },
				],
			},
		],
	},
];

export default routes