import React, { useEffect } from "react";
import useAlert from '@/common/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { QrType } from '@/common/models/qr';
import { useMutation } from 'react-query';
import OrderService from '@/common/api/OrderService';
import CourierService from '@/common/api/CourierService';
import { OrderItem } from '@/common/models/order';
import { Statuses } from '@/common/constants/statuses';
import { getDeliveryPartyCity } from '@/common/utils/utils';
import { replaceCyrillicWithEnglish } from '@/common/utils/qrUtils';
import { RegexPatterns } from '@/common/constants/regexPatterns';

const ScannerHandlerComponent = ({scanInstance, setScanInstance, orders, setOrders, setError, fetchAllData}) => {

  const { t } = useTranslation()
  const { openNotification } = useAlert()
  const { mutate: findInstance } = useMutation(['get-scan-instance'], ({ type, id }) => {
    setScanInstance({type: type, id: id, instance: null})
    if(type === QrType.COURIER) {
      return CourierService.getById(id)
    }
  }, {
    onSuccess: (data) => {
      setScanInstance(prev => ({
        ...prev,
        instance: data
      }))
    },
  })
  const allowedStatuses = Statuses.courierScanChangeableStatuses
  const { mutate: fetchOrder } = useMutation(['add-scanned-order'],
    (id) => OrderService.getById(id),
    {
      onSuccess: (data: OrderItem) => {
        if(allowedStatuses.includes(data?.status)) {
          setOrders(prev => [...prev, data])
          openNotification('success', t('scannerPage.success.addOrder.header', {id: data?.id}), t('scannerPage.success.addOrder.text'))
        } else {
          openNotification('error', t('scannerPage.errors.badOrderStatus.header', {id: data?.id, status: t(`orderStatus.${data?.status}`, {from_city: getDeliveryPartyCity(data?.deliverySender), to_city: getDeliveryPartyCity(data?.deliveryReceiver)})}), t('scannerPage.errors.badOrderStatus.text'), 0)
        }
      },
      onError: (_, id) => {
        openNotification('error', t('scannerPage.errors.noOrdersFound.header', {id: id}), t('scannerPage.errors.noOrdersFound.text'))
      }
    },
  );

  useEffect(() => {
    let buffer = "";
    let timeout;

    const processScannedData = (data) => {
      const isOrderItem = data?.type === QrType.ORDER_ITEM || (Object.keys(data).includes('orderId') && Object.keys(data).includes('id'))
      if(isOrderItem && !(scanInstance?.type || scanInstance?.id)) {
        setError(t('scannerPage.errors.noCourier'))
      } else {
        setError('')
        if(data?.type === QrType.COURIER) {
          if (!scanInstance?.id) {
            findInstance({type: data?.type, id: data?.id})
          } else if(scanInstance?.id === data?.id) {
            if(orders?.length < 1) {
              openNotification('error', t('scannerPage.errors.noOrders.header'), t('scannerPage.errors.noOrders.text'), 0)
            } else {
              fetchAllData()
            }
          } else {
            openNotification('error', t('scannerPage.errors.courierNotMatched.header'), t('scannerPage.errors.courierNotMatched.text'), 0)
          }
        }
        if(isOrderItem) {
          if(orders?.find(order => order?.id === data?.id)) {
            openNotification('warning', t('scannerPage.info.orderExists.header', {id: data?.id}), t('scannerPage.info.orderExists.text'))
          }
          else {
            fetchOrder(data?.id)
          }
        }
      }
    };
    const handleKeyPress = (event) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        buffer = "";
      }, 300); // Timeout for next scan

      if (event.key === "Enter") {
        const standardizedData = standardizeScannedData(buffer);
        if(standardizedData && standardizedData?.id) {
          processScannedData(standardizedData);
        } else {
          openNotification('error', t('scannerPage.errors.badQR.header'), t('scannerPage.errors.badQR.text'), 0)
        }
        buffer = "";
      } else {
        buffer += event.key;
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
      clearTimeout(timeout);
    };
  }, [fetchAllData, fetchOrder, findInstance, openNotification, orders, scanInstance, scanInstance?.id, scanInstance?.type, setError, setScanInstance, t]);

  const standardizeScannedData = (data) => {

    // Step 1: Replace Russian with English counterparts
    const replacedData = replaceCyrillicWithEnglish(data);

    // Step 2: Remove invalid characters to make it JSON-parsable
    const sanitizedData = replacedData.replace(RegexPatterns.redundantCharacters, ''); // Only allow valid JSON characters

    // Step 3: Parsing JSON
    try {
      return JSON.parse(sanitizedData);
    } catch (error) {
      console.error("Error parsing replaced data:", error);
      return null;
    }
  };

  return (
    <></>
  );
};

export default ScannerHandlerComponent;
