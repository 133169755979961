import {useMemo, useState} from "react"
import {SortOption} from "@/common/models/util";
import { SortDirection } from "../constants/util"

/**
 * Custom reusable hook for managing sorting of data.
 */
const useSort = <T>(
    data: T[] = [],
    sortOptions: SortOption[],
    setSortOptions: (options: SortOption[]) => void = () => {},
    exclusive: boolean = false
) => {
    const [sortTarget, setSortTarget] = useState<string | null>()

    const onSortChange = (key: string, target?: string) => {
        const existingSort: SortOption = sortOptions?.find(s => s.key === key)
        let direction = existingSort?.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending

        if(exclusive) {
            setSortOptions([{ key, direction }])
        } else {
            if (existingSort) {
                setSortOptions(sortOptions.map(s => s.key === key ? { ...s, direction } : s))
            } else {
                setSortOptions([...sortOptions, { key, direction }])
            }
        }
        setSortTarget(target)
    }

    const getNestedProperty = (obj: any, path: string | null): any => {
        if (!path) {
            return obj
        }

        const args: string[] = path.split('.')

        if (args.length < 2) {
            return obj && obj[path]
        }

        return args.reduce((acc, key) => 
            acc && acc[key], obj)
    }
    const sortedData = useMemo(() => {
        return [...data].sort((a, b) => {
            for (let option of sortOptions) {
                const { key, direction } = option
                const aValue = getNestedProperty(a[key], sortTarget)
                const bValue = getNestedProperty(b[key], sortTarget)

                if (!aValue) 
                    return 1

                if (!bValue) 
                    return -1

                if (aValue < bValue) {
                    return direction === SortDirection.Ascending ? -1 : 1
                }

                if (aValue > bValue) {
                    return direction === SortDirection.Ascending ? 1 : -1
                }
            }

            return 0
        })
    }, [data, sortOptions, sortTarget])

    const getSortDirection = (key: string) => {
        const option = sortOptions?.find(s => s.key === key)

        if (!option) {
            return
        }

        return option.direction
    }

    return { sortedData, sortOptions, onSortChange, getSortDirection } as const
}

export default useSort
