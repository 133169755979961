import SearchBar from '@/pages/order/components/searchFilter/SearchBar';
import { Button } from 'antd';
import { Funnel, FunnelFill } from 'react-bootstrap-icons';
import FilterBar from '@/pages/order/components/searchFilter/FilterBar';
import useDebounce from '@/common/hooks/useDebounce';
import useAuth from '@/common/hooks/useAuth';
import useOrderSearch from '@/common/hooks/useOrderSearch';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

const searchFilter: FC<searchFilterProps> = observer(({ resetPage, isFilterActive, setIsFilterActive }) => {
  const {user} = useAuth()
  const { setSearchType, setSearchQuery, persistFilterQuery } = useOrderSearch()

  const refetchWithDebounce = useDebounce((query) => {
    setSearchQuery(query)
  }, 600)
  const resetPageWithDebounce = useDebounce(resetPage, 200)

  const handleSearchOptionChange = async (option: string) => {
    setSearchType(option)
    setSearchQuery('')
  }

  const handleSearchInputChange = async (value: string) => {
    await resetPageWithDebounce()
    refetchWithDebounce(value)
  }

  return (
    <>
      <div className="border border-rounded-1 my-1 p-3">
        <div className="d-flex">
          <SearchBar
            role={user.roles[0]}
            className="flex-grow-1"
            onSelect={handleSearchOptionChange}
            onChange={handleSearchInputChange}
          />
          <div className="px-1"></div>
          <Button
            className="d-flex border align-items-center border-rounded-1"
            variant="outline-light"
            size="sm"
            onClick={() => setIsFilterActive(!isFilterActive)}
          >
            {isFilterActive ? (
              <FunnelFill color="dark"/>
            ) : (
              <Funnel color="dark"/>
            )}
          </Button>
        </div>
        {isFilterActive && (
          <div className="mt-1">
            <div className="py-2">
              <span className="heading-font">Фильтры</span>
            </div>
            <FilterBar
              onChange={(option) => {
                persistFilterQuery({...option})
                resetPage()
              }}
            />
          </div>
        )}
      </div>
    </>
  );
})

interface searchFilterProps {
  resetPage: () => void,
  isFilterActive: boolean,
  setIsFilterActive: (value: boolean) => void
}

export default searchFilter;