import { useState } from 'react';

import OrdersView from '@/pages/order/components/ordersView/index';
import useAuth from '@/common/hooks/useAuth';
import usePage from '@/common/hooks/usePage';
import OrderImportWrapper from '@/pages/order/components/ordersView/OrderImportWrapper';
import SearchAndFilterSection from '@/pages/order/components/searchFilter/index';

const OrderViewPage = () => {
  const { user } = useAuth();
  const { page, nextPage, previousPage, currentPage, resetPage, lastPage, setPage, pageSize, setPageSize } = usePage();
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState(false);

  return (
    <div className='pt-3 position-relative'>
      {/* Import Modal */}
      <OrderImportWrapper showImportModal={showImportModal} setShowImportModal={setShowImportModal} />

      {/* Header */}
      <div className={'d-flex justify-content-between align-items-center'}>
        <p className='heading-2 my-0'>Заказы</p>
      </div>

      {/* SearchAndFilter */}
      <SearchAndFilterSection
        resetPage={resetPage}
        isFilterActive={isFilterActive}
        setIsFilterActive={setIsFilterActive}
      />

      {/* Order table and SelectBar */}
      <OrdersView
        isFilterActive={isFilterActive}
        role={user.roles[0]}
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        pagination={{ page, nextPage, previousPage, currentPage, resetPage, lastPage, setPage, pageSize, setPageSize }}
      />
    </div>
  );
};

export default OrderViewPage;
