import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { Spinner } from "react-bootstrap"

import routeConfig from "./common/config/routeConfig"
import queryClientConfig from "./common/config/queryClientConfig"
import {ConfigProvider} from "antd";
import ruRU from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru';


const App = () => {
	const router = createBrowserRouter(routeConfig)
  	const queryClient = new QueryClient(queryClientConfig)

	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider
				locale={ruRU}
				theme={{
					token: {
						colorPrimary: '#7B57DF',
						fontFamily: '"Gilroy"',
						fontSize: 16,
					},
				}}
			>
				<RouterProvider router={router} fallbackElement={<Spinner />} />
			</ConfigProvider>
		</QueryClientProvider>
	)
}

export default App
