import { FC, useState } from 'react';
import OrdersContentTable from './OrdersContentTable';
import { Roles } from '@/common/constants/roles';
import OrdersTabs from '@/pages/order/components/ordersView/OrdersTabs';
import OrderExportXlsx from '@/pages/order/components/ordersView/OrderExportXLSX';
import { Button, Typography } from 'antd';
import clipboardIcon from '@/assets/icons/import/clipboard-import.svg';
import OrderItemSort from '@/pages/order/components/ordersView/OrderItemSort';
import SelectBar from '@/pages/order/components/ordersView/SelectBar';
import { buildFetchOrdersQuery } from '@/common/utils/utils';
import { useQuery } from 'react-query';
import { OrderApiPageResponse } from '@/common/models/api/v0/order.dto';
import OrderService from '@/common/api/OrderService';
import useOrderSearch from '@/common/hooks/useOrderSearch';
import useAuth from '@/common/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useImport from '@/common/hooks/useImport';
import { observer } from 'mobx-react-lite';

const OrdersView: FC<OrdersViewProps> = observer(({ isFilterActive, role = Roles.Client, setShowImportModal, pagination: {page, nextPage, previousPage, currentPage, resetPage, lastPage, setPage, pageSize, setPageSize} }) => {
  const {t} = useTranslation()
  const {setImportForm} = useImport()
  const [isTableLoading, setTableLoading] = useState(false)

  const {user, hasAnyRole} = useAuth()

  const {searchType, searchQuery, persistedFilterQuery, persistFilterQuery, persistedSortOptions } = useOrderSearch();

  const filter = buildFetchOrdersQuery(searchQuery, searchType, persistedFilterQuery, currentPage, pageSize, persistedSortOptions[0]);
  const {
    data,
    isFetching,
    error,
    refetch,
  } = useQuery<OrderApiPageResponse>(['orders', currentPage, pageSize, searchQuery, searchType, persistedFilterQuery, persistedSortOptions],
    () => OrderService.getAll(filter));

  const handleAnyChange = () => {
    refetch();
  };

  return (
    <div>
      <div className={'mx-3 mt-2 mb-3 d-flex justify-content-between'}>
        <OrdersTabs
          key={persistedFilterQuery?.deliveryStatuses}
          onChange={(option) => {
            persistFilterQuery({ ...option });
            resetPage();
          }}
        />
        <div className={'d-flex'}>
          <OrderExportXlsx key={filter} filter={filter} isClient={user.roles.includes(Roles.Client)} />
          {
            (hasAnyRole([Roles.Manager, Roles.Admin])) && (
              <Button
                onClick={() => {
                  setShowImportModal(true);
                  setImportForm({
                    step: 0,
                    type: '',
                    fileName: '',
                    fileSize: '',
                    orders: [],
                    brokenOrders: [],
                    initialOrders: [],
                  });
                }}
                size={'large'}
                type={'primary'}
                style={{ padding: '0 1rem', marginLeft: '1rem' }}
              >
                <Typography.Text
                  strong
                  style={{ color: 'white', fontSize: '0.8rem', margin: 0 }}
                >{t('ordersPage.buttons.importOrders')}</Typography.Text>
                <img className='edit-img' src={clipboardIcon} alt='clipboard-import' />
              </Button>
            )
          }
        </div>
      </div>
      <div className={`d-flex justify-content-start align-items-center`}>
        <OrderItemSort />
      </div>

      <div style={{ overflowY: 'auto', height: isFilterActive ? '52dvh' : '63dvh' }}>
        <OrdersContentTable
          orders={data?.orders}
          isLoading={isFetching || isTableLoading}
          error={error}
          expanded={role === Roles.Manager}
          onAnyChange={handleAnyChange}
          refetch={refetch}
        />
      </div>
        <SelectBar
          data={data}
          controlPage={{ page, nextPage, previousPage, currentPage, resetPage, lastPage, setPage, pageSize, setPageSize }}
          onEdit={refetch}
          setTableLoading={setTableLoading}
        />
    </div>

  );
})

interface OrdersViewProps {
  isFilterActive: boolean,
  setShowImportModal: (value: boolean) => void,
  role?: string,
  pagination: {
    page: number,
    currentPage: number,
    nextPage: () => void,
    previousPage: () => void,
    resetPage: () => void,
    lastPage: (page: number) => void,
    setPage: (page: number) => void,
    pageSize: number,
    setPageSize: (size: number) => void,
  }
}

export default OrdersView;
