import { OrderItem } from "../models/order"
import { OrderItemQrValue, QrType } from "../models/qr"
import { RegexPatterns } from '@/common/constants/regexPatterns';
import { russianToEnglishMap } from '@/common/constants/locales/ru/russianToEnglishMap';

export const getOrderItemQrValue = (orderItem: OrderItem): string => {
    const qrValue: OrderItemQrValue = {
        type: QrType.ORDER_ITEM,
        id: orderItem.id,
        orderId: orderItem.orderId,
    } 

    return JSON
        .stringify(qrValue)
        .replace(/"/g, '\\"')
}

export function replaceCyrillicWithEnglish(input: string) {
    let output = input;

    // If the text contains Russian letters, replace them
    if (RegexPatterns.RUSSIAN_CYRILLIC_REGEX.test(output)) {
        output = output.replace(RegexPatterns.RUSSIAN_CYRILLIC_REGEX, (char: string) => {
            return russianToEnglishMap[char] ?? char;
        });
    }
    return output;
}