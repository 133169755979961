import { makeAutoObservable } from "mobx"

import AuthService from "../api/AuthService"
import { User } from "../models/user"
import { Persistable } from "./persistable"
import tokenStore from "./tokenStore"
import {UserService} from "@/common/api/UserService";
import orderCreateFormStore from "@/common/storage/orderCreateFormStore";
import resourceStore from "./resourceStore"

class AuthStore implements Persistable {
    isNew: boolean = true
    isLoading: boolean = false
    user: User = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadFromLocalStorage()
    }

    async refetchUser() {
        try {
            this.isLoading = true
            const user = await UserService.getMe()
            this.setUser(user)
        } catch (error) {
            throw error
        } finally {
            this.isLoading = false
        }
    }

    async signIn(username: string, password: string) {
        try {
            this.isLoading = true
            const response = await AuthService.signIn(username, password)
            tokenStore.setAuthentication(response)
            const user = await UserService.getMe()
            this.setUser(user)

            if(this.requiresOnboard()){
                this.setIsNew(true)
            }

            resourceStore.fetchResources()
        } catch (error) {
            throw error
        } finally {
            this.isLoading = false
        }
    }

    async create(username: string, password: string) {
        try {
            this.isLoading = true
            await AuthService.create(username, password, 'CLIENT')
            this.signIn(username, password)
        } catch (error) {
            throw error
        } finally {
            this.isLoading = false
        }
    }

    async signOut() {
        this.user = null
        orderCreateFormStore.resetFormState()
        localStorage.clear()
    }

    hasRole(role: string) {
        return this.user && this.user.roles.includes(role)
    }

    hasAnyRole(roles: string[]) {
        if (roles && !roles.length) return true;
        return this.user && roles
            .filter(role => this.user.roles.includes(role))
            .length > 0
    }

    requiresOnboard() {
        const company = this.user.company

        return (company === null || company === undefined) ||
            company.name === null ||
            company.bin === null ||
            company.streetName === null ||
            company.apartment === null
    }

    isAuthenticated() {
        return this.user !== null
    }

    setUser(user: User) {
        if (user.company) {
            this.setIsNew(false)
        }
        this.user = {...user, roles: user.roles.reverse()}
        this.writeToLocalStorage()
    }

    setIsNew(isNew: boolean) {
        this.isNew = isNew
        this.writeToLocalStorage()
    }

    writeToLocalStorage() {
        if (this.user) {
            localStorage.setItem('user', JSON.stringify(this.user))
            localStorage.setItem('is-new', JSON.stringify(this.isNew))
        }
    }

    removeFromLocalStorage() {
        localStorage.removeItem('user')
    }

    loadFromLocalStorage() {
        const user = localStorage.getItem('user')
        const isNew = localStorage.getItem('is-new')
        if (user) {
            this.user = JSON.parse(user)
            this.isNew = JSON.parse(isNew)
        }
    }
}

const authStore = new AuthStore()

export default authStore
