/**
 * Data types related associated with:
 * - Order item;
 * - Order search and filter;
 * - Order create.
 */

import { Courier } from "./courier"
import { PagingParams } from "./util"
import { DeliveryParty, DeliveryPartyCreateData } from "./deliveryParty"
import { DeliveryProcessType } from "../constants/deliveryProcess"
import { User } from "./user"

export interface Order {
    id: number,
    createDate: string,
    status: string,
    deliverySender: DeliveryParty,
    orderItems: OrderItem[],
}

export interface OrderItem {
    id: number,
    orderId?: number,
    actualDeliveryDate?: string,
    itemPartsCount?: number,
    deliveryProcessType?: DeliveryProcessType,
    ownerUser?: User,
    createDate?: string,
    status?: string,
    orderNumber?: string,
    provider?: string,
    route?: string,
    cell?: string,
    comments?: string,
    parcelType?: string,
    deliveryType?: string,
    estimatedDeliveryDate?: string,
    calculatedCost?: number,
    courier?: Courier,
    courierDelivered?: Courier,
    deliveryReceiver?: DeliveryParty,
    deliverySender?: DeliveryParty,
    thirdPartyTrackingNumber?: string | null,
    thirdPartyProviderStatus?: string | null,
}

export enum deliveryProviderTypeEnum {
    OWN_DELIVERY = 'OWN_DELIVERY',
    THIRD_PARTY_DELIVERY = 'THIRD_PARTY_DELIVERY'
}

export type OrderItemProviderChanges = {
    orderItemId: number,
    deliveryProviderType: deliveryProviderTypeEnum,
}

export interface OrderItemChange {
	id: number,
    createDate: string,
    orderId?: number,
    status?: string,
    comments?: string,
    courier?: Courier,
}

export interface ChangedAddress {
    initValue: string,
    changedValue: string,
}

export interface SearchDirtyApiResponse {
    data: SuggestedAddress[]
}

export interface SearchDirtyRequestBody {
    addresses: dirtyAddress[]
}

export type dirtyAddress = {
    id: number,
    address: string,
    apartments: string,
    apartmentsTotal: number,
    bin: string,
    name: string,
    total: string
}

export type SuggestedAddress = {
    id: number,
    cityId: number,
    street: string,
    building: string,
    point: {
        lat: number | null,
        lon: number | null
    },
    addressComments: string,
    contactPhone: string,
    queryAddress: string,
    status: string
}

export interface OrderCreateData {
    orderRequesterId?: number;
    deliverySender?: DeliveryPartyCreateData;
    orderItems?: OrderItemCreateData[];
    changedAddresses: ChangedAddress[];
}

export interface OrderItemCreateData {
    createDate?: string,
    status?: string,
    deliveryType?: string,
    parcelType?: string,
    comments?: string;
    deliveryReceiver?: DeliveryPartyCreateData;
}

export interface ImportTableOrder extends OrderItemCreateData{
    key: React.Key,
    id: number,
    Sender: string,
    isFixed?: boolean,
}

export interface OrderSearchFilter extends PagingParams {
    fromCreateDate?: string,
    toCreateDate?: string,
    deliveryType?: string,
    deliveryProcessType?: string,
    parcelType?: string,
    deliveryStatuses?: string,
}

export interface OrderSearchQuery extends OrderSearchFilter{
    orderItemId?: number,
    orderId?: number,
    receiverName?: string,
    receiverBin?: string,
    courier?: string,
    zone?: string,
    createDateOption?: string,
}

export interface OrderAttachmentItem {
    id: number,
    orderItemId: number,
    createDate: string,
    file: string
}

export interface OrderScanTableItem {
    key: number,
    id: number,
    receiverName: string,
    createDate: string,

}