import { useMemo, useState } from "react"

const usePage = () => {
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(20)

    const nextPage = () => {
        setPage(page + 1)
    }

    const previousPage = () => {
        setPage(page - 1)
    }

    const resetPage = () => {
        setPage(1)
    }

    const lastPage = (page) => {
        setPage(page)
    }

    const getCurrentPage = (): number => page !== 0 ? page - 1 : page

    const currentPage = useMemo(getCurrentPage, [page])

    return { page, nextPage, previousPage, currentPage, resetPage, lastPage, setPage, pageSize, setPageSize }
}

export default usePage
