import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CourierStatusMode, Statuses } from '@/common/constants/statuses'
import useFetchOrders from '@/common/hooks/useFetchOrders'
import useSearch from '@/common/hooks/useSearch'
import useSort from '@/common/hooks/useSort'
import { Order } from '@/common/models/order'
import OrderCard from './OrderCard'
import useAlert from '@/common/hooks/useAlert'
import LoadingList from '@/telegram/components/placeholder/LoadingList'
import NoDataList from '@/telegram/components/placeholder/NoDataList'
import { SortDirection } from '@/common/constants/util'

const OrderList = () => {
    const { t } = useTranslation()

    const { openNotification } = useAlert()
    const { orders, isFetching, refetch: refetchOrders } = useFetchOrders(
        Statuses.courierAppStatusLists[CourierStatusMode.PICKUP], 
        (err) => openNotification('error', err.response?.body?.errorMessage || t('api.messages.serviceDown'))
    )

    const { sortedData } = useSort<Order>(orders, [{ key: 'createDate', direction: SortDirection.Descending }])

    const [searchType, setSearchType] = useState<string>('deliverySender.address.addressString')
    const [searchQuery, setSearchQuery] = useState<string>('')
    const { searchResult } = useSearch<Order>(sortedData, searchType, searchQuery)

    const handleSearchTypeChange = (value: string) => {
        setSearchType(value)
        setSearchQuery('')
    }

    const handleSearchInput = (value: string) => {
        setSearchQuery(value)
    }

    const handleOrderCardClick = (order: Order) => {
        const data = JSON.stringify(order)
        navigate('/telegram/courier/order/view', { state: { order: data } })
    }

    const navigate = useNavigate()

    return isFetching ? (
        <LoadingList />
    ) : (
        <div>
            <div className="py-3">
                <InputGroup className="d-flex">
                    <Form.Select
                        style={{ flex: '1' }}
                        className="border-rounded-1 flex-1"
                        onChange={(e: any) => handleSearchTypeChange(e.target.value)}
                    >
                        <option value="deliverySender.address.addressString">{t('telegram.pages.orderAction.deliverySenderAddress')}</option>
                        <option value="id">{t('telegram.pages.orderAction.orderId')}</option>
                        <option value="deliverySender.company.name">{t('telegram.pages.orderAction.deliverySender')}</option>
                    </Form.Select>
                    <Form.Control
                        type={searchType === 'orderItemId' ? 'number' : 'text'}
                        style={{ flex: '4' }}
                        className="border-rounded-1 flex-3"
                        placeholder="Введите запрос"
                        onChange={(e: any) => handleSearchInput(e.target.value)}
                    />
                </InputGroup>
            </div>
            <div className="d-flex align-items-center">
                <span className="heading-font">{t('telegram.pages.orderAction.counter.total')} {searchResult.length}</span>
                <span
                    className="ms-auto d-flex align-items-center border border-rounded-1 bg-light p-2"
                    onClick={() => refetchOrders()}
                >
                    <ArrowRepeat size="25" />
                </span>
            </div>
            {searchResult.length > 0 ? (
                <div>
                    {searchResult.map((order, index) => (
                        <OrderCard 
                            key={index} 
                            data={order} 
                            onClick={() => handleOrderCardClick(order)}
                        />
                    ))}
                </div>
            ) : (
                <NoDataList />
            )}
        </div>
    )
}

export default OrderList