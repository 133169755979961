import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Card, Flex, Image, Modal, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import './index.scss';
import { Courier } from '@/common/models/courier';
import OrderService from '@/common/api/OrderService';
import { OrderAttachmentsResponse } from '@/common/models/api/v0/order.dto';
import { OrderAttachmentItem } from '@/common/models/order'
import useAlert from '@/common/hooks/useAlert'
import useAuth from '@/common/hooks/useAuth'
import { Roles } from '@/common/constants/roles'

const { Title } = Typography;

type DeletePopupT = {
  isOpen: boolean,
  isLoading: boolean,
  image?: OrderAttachmentItem,
}

const OrderAttachmentViewPage = () => {
  const { t } = useTranslation()
  let { orderId: orderItemId } = useParams();
  const location = useLocation();
  const { openNotification } = useAlert()
  const { hasAnyRole } = useAuth()
  const courier: Courier = location?.state?.courier;
  const {
    data: ordersWithAttachments,
    isFetching: isOrdersFetching,
    refetch: refetchAttachments,
  } = useQuery<OrderAttachmentsResponse>(['order-attachment'], () => OrderService.getOrderAttachments(Number(orderItemId)));

  const [deletePopup, setDeletePopup] = useState<DeletePopupT>({
    isOpen: false,
    isLoading: false,
  })

  const setDeletePopupIsOpen = (isOpen: boolean) =>
    setDeletePopup(prevState => ({ ...prevState, isOpen }))

  const setDeletePopupImage = (image: OrderAttachmentItem) =>
    setDeletePopup(prevState => ({ ...prevState, image }))

  const setDeletePopupIsLoading = (isLoading: boolean) =>
    setDeletePopup(prevState => ({ ...prevState, isLoading }))

  const handleDeletePopupOpen = (image: OrderAttachmentItem) => {
    setDeletePopupImage(image)
    setDeletePopupIsOpen(true)
  }

  const handleDeletePopupCancel = () => setDeletePopupIsOpen(false)

  const handleDeletePopupConfirm = () => {
    setDeletePopupIsLoading(true)
    OrderService.deleteFile(Number(orderItemId), deletePopup.image.id)
      .then(() => {
        refetchAttachments()
        setDeletePopupIsOpen(false)
      })
      .catch((e) => openNotification('error', e.response?.body?.errorMessage || t('api.messages.serviceDown')))
      .finally(() => setDeletePopupIsLoading(false))
  }

  return (
    <Flex className={'attach'} vertical={true}>
      {
        (isOrdersFetching) && (
          <Spin indicator={
            <LoadingOutlined
              className={'attach-spinner'}
              spin />
          } />
        )
      }

      {
        (!isOrdersFetching && ordersWithAttachments?.length < 1) && (
          <Title className={'attach-title'} level={2} style={{ marginTop: '1.25rem' }}>В этом заказе нет вложений</Title>
        )
      }

      {
        (ordersWithAttachments?.length > 0 && !isOrdersFetching) && (
          <>
            <Title
              className={'attach-title'}
              level={2}
            >
              {t('attachmentPage.header', {
                firstName: courier?.firstName,
                lastName: courier?.lastName,
              })}
            </Title>
            <Image.PreviewGroup
              preview={{
                toolbarRender: (
                  _,
                  {
                    transform: { scale },
                    actions: {
                      onActive,
                      onFlipY,
                      onFlipX,
                      onRotateLeft,
                      onRotateRight,
                      onZoomOut,
                      onZoomIn,
                      onReset,
                    },
                  },
                ) => (
                  <Space size={12} className='toolbar-wrapper'>
                    <LeftOutlined onClick={() => onActive?.(-1)} />
                    <RightOutlined onClick={() => onActive?.(1)} />
                    <SwapOutlined rotate={90} onClick={onFlipY} />
                    <SwapOutlined onClick={onFlipX} />
                    <RotateLeftOutlined onClick={onRotateLeft} />
                    <RotateRightOutlined onClick={onRotateRight} />
                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    <UndoOutlined onClick={onReset} />
                  </Space>
                ),
              }}
            >
              <Flex
                wrap={'wrap'}
              >
                {ordersWithAttachments?.map((image) => (
                  <Card
                    hoverable
                    className='attach-card'
                  >
                    {hasAnyRole([Roles.Admin, Roles.Manager]) && (
                      <span className="trash-container" onClick={() => handleDeletePopupOpen(image)}>
                        <DeleteOutlined className="trash-icon" />
                      </span>
                    )}
                    <Image key={image?.id} src={image?.file} height={200} />
                  </Card>
                ))}
              </Flex>
              <Modal
                open={deletePopup.isOpen}
                onCancel={handleDeletePopupCancel}
                onOk={handleDeletePopupConfirm}
                title={t('attachmentPage.deletePopup.title', { id: deletePopup.image?.id })}
                footer={() => (
                  <>
                    <Button type="primary" danger onClick={handleDeletePopupConfirm} loading={deletePopup.isLoading}>
                      {t('attachmentPage.deletePopup.buttons.confirmBtn')}
                    </Button>
                    <Button onClick={handleDeletePopupCancel}>
                      {t('attachmentPage.deletePopup.buttons.cancelBtn')}
                    </Button>
                  </>
                )}
              >
                <div>
                  <span>{t('attachmentPage.deletePopup.description')}</span>
                </div>
                <Image key={deletePopup.image?.id} src={deletePopup.image?.file} height={200} />
              </Modal>
            </Image.PreviewGroup>
          </>
        )
      }
    </Flex>
  );
};

export default OrderAttachmentViewPage;