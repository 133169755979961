import { Pagination, PaginationProps } from "antd"
import { useTranslation } from "react-i18next"

const AntPagination = ({controlPage: {page, setPage, total, pageSize, setPageSize}}) => {
  const { t } = useTranslation()

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const onSizeChange = (_current: number, size: number) => {
    setPageSize(size)
  }

  const pagination: PaginationProps = {
    locale: {
      items_per_page: t('pagination.orders'),
      jump_to: t('pagination.jump_to'),
      page: t('pagination.page'),
      prev_page: t('pagination.prev_page'),
      next_page: t('pagination.next_page'),
    },
    total: total,
    showTotal: (total, range) =>  t('pagination.total', {range: `${range[0]}-${range[1]}`, total: total}),
    showSizeChanger: true,
    showQuickJumper: true,
    current: page,
    pageSize: pageSize,
    pageSizeOptions: [20, 50, 100, 200, 500],
    onShowSizeChange: onSizeChange,
    onChange: onPageChange
  }

  return <Pagination
    {...pagination}
  />;
}

export default AntPagination;