import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import printStore from '@/common/storage/PrintStore';

export const PrintContext = createContext({
  printStore
})


const PrintProvider = () => {
  return <PrintContext.Provider value={{ printStore }}>
    <Outlet />
  </PrintContext.Provider>
}

export default PrintProvider