import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import { useTranslation } from 'react-i18next'

import useOrderSearch from "@/common/hooks/useOrderSearch";
import {OrderApiPageResponse} from "@/common/models/api/v0/order.dto";
import {Statuses} from "@/common/constants/statuses";
import SearchableSelect from "@/pages/order/components/ordersView/SearchableSelect";
import OrderService from "@/common/api/OrderService";
import {orderStatusMap} from "@/components/controls/order-action/actions";
import {cellOptions} from "@/pages/order/options";
import RouteCellSelect from "@/pages/order/components/ordersView/RouteCellSelect";
import {OrderItem} from "@/common/models/order";
import ConfirmModal from '@/pages/order/components/ConfirmModal';
import { OrderProviderSelect } from '@/pages/order/components/OrderProviderSelect';
import { DeliveryProcessType } from '@/common/constants/deliveryProcess'
import useAlert from '@/common/hooks/useAlert';
import { usePrint } from '@/common/hooks/usePrint';
import { Roles } from '@/common/constants/roles';
import useAuth from '@/common/hooks/useAuth';
import AntPagination from '@/components/controls/antPagination/AntPagination';
import { printCouriers } from '@/common/utils/printUtils';

interface SelectBarProps {
    data: OrderApiPageResponse,
    controlPage: {
        page: number,
        nextPage: () => void,
        previousPage: () => void,
        resetPage: () => void,
        lastPage: (page: number) => void,
        setPage: (page: number) => void,
        pageSize: number,
        setPageSize: (size: number) => void,
    },
    onEdit: () => void,
    setTableLoading: (value: boolean) => void
}


const SelectBar = observer(({data, controlPage, onEdit, setTableLoading}: SelectBarProps) => {
    const { t } = useTranslation()
    const { setStickers, setNotes } = usePrint()
    const { openNotification } = useAlert()
    const {selectedOrders, setSelectedOrders, refreshSelectedOrders} = useOrderSearch()
    const [status, setStatus] = useState(null)
    const [isSelectBarLoading, setIsSelectBarLoading] = useState(false)
    const {hasAnyRole} = useAuth()

    const handlePrintStickers = () => {
        const unmarkedOrders = selectedOrders.filter(order => !Statuses.printAccessibleStatuses.includes(order?.status))
        if(unmarkedOrders.length > 0) {
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.markAllOrdersToPrintStickers')}`)
        } else {
            setStickers(selectedOrders)
            window.open(`${window.location.origin}/order-stickers-print`, '_blank');
        }
    }

    const handlePrintCouriers = () => {
        printCouriers({orderItems: selectedOrders, openNotification})
    }

    const handlePrintNotes = () => {
        setNotes(selectedOrders)
        window.open(`${window.location.origin}/orders-notes-print`, '_blank');
    }

    const handleCouriersSearch = async (selectedOption) => {
        let improperStatusOrders = selectedOrders.filter(orderItem => !Statuses.courierChangeStatuses.find(status => status === orderItem?.status))
        if(improperStatusOrders.length > 0) {
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.selectedOrdersHaveIncorrectStatus')}`)
        } else {
            let courier;
            if (typeof selectedOption === "string") {
                courier = selectedOption;
            } else {
                courier = selectedOption.value;
            }
            try {
                setTableLoading(true)
                await assignCouriers(courier)
                openNotification('success', `${t('ordersPage.selectBar.alerts.success.courierAssigned')}`, false)
                await refreshSelectedOrders()
                onEdit()
            } catch (e) {
                openNotification('error', `${t('ordersPage.selectBar.alerts.fail.serverError')}`)
            } finally {
                setTableLoading(false)
            }
        }
    }

    const handleCellChange = async (selectedOption) => {
        let improperStatusOrders = selectedOrders.filter(orderItem => orderItem?.status === 'NEW')
        if(improperStatusOrders.length > 0) {
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.selectedOrdersShouldbeConfirmed')}`)
        } else if(selectedOption){
            try {
                setTableLoading(true)
                await assignCells(selectedOption)
                openNotification('success', `${t('ordersPage.selectBar.alerts.success.cellAssigned')}`)
                onEdit()
            } catch (e) {
                openNotification('error', `${t('ordersPage.selectBar.alerts.fail.serverError')}`)
            } finally {
                setTableLoading(false)
            }
        }
    }

    const assignCouriers = async (courier) => {
        try {
            setTableLoading(true);
            for (const orderItem of selectedOrders) {
                let updatedOrderItem: OrderItem = {courier: courier}
                await OrderService.updateOrderItem(updatedOrderItem, orderItem?.id)
                if(Statuses.courierAssignableStatuses[orderItem?.status]){
                    updatedOrderItem = {...updatedOrderItem, status: Statuses.courierAssignableStatuses[orderItem?.status]}
                    await OrderService.changeStatus(orderItem?.id, orderStatusMap[orderItem?.status]?.events[0].event)
                }
            }
        } catch (e) {
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.serverError')}`)
        } finally {
            setTableLoading(false);
        }
    }
    const assignCells = async (cell) => {
        try {
            setTableLoading(true)
            for (const orderItem of selectedOrders) {
                let updatedOrderItem = {...orderItem, cell: cell}
                await OrderService.updateOrderItem(updatedOrderItem, orderItem?.id)
            }
        } catch (e) {
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.serverError')}`)
        } finally {
            setTableLoading(false)
        }

    }

    const handleChangeStatuses = async (event) => {
        const improperStatusOrders = selectedOrders.filter(orderItem => orderItem?.status !== selectedOrders[0]?.status)
        const isQazpost = selectedOrders.every(orderItem => orderItem?.deliveryProcessType === DeliveryProcessType.THIRD_PARTY_PROVIDER)

        if(improperStatusOrders.length > 0){
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.selectedOrdersHaveDifferentStatuses')}`)
            setStatus(null)
        }
        else if (Statuses.courierAssignableStatuses[selectedOrders[0].status] && !isQazpost){
            openNotification('error', `${t('ordersPage.selectBar.alerts.fail.assignCourier')}`)
            setStatus(null)
        }
        else {
            try {
                setIsSelectBarLoading(true)
                for (const order of selectedOrders) {
                    await OrderService.changeStatus(order.id, event.event)
                }
                openNotification('success', `${t('ordersPage.selectBar.alerts.success.statusChanged')}`)
                setStatus(null)
                onEdit()
                refreshSelectedOrders(selectedOrders)
            } catch (e) {
                openNotification('error', `${t('ordersPage.selectBar.alerts.fail.serverError')}`)
                setStatus(null)
            } finally {
                setIsSelectBarLoading(false)
            }
        }
    }

    const getOrderWithEvent = (orders: OrderItem[]) => {
        let orderWithEvent = null
        for (const orderItem of orders) {
            if(orderStatusMap[orderItem?.status]?.events
                .filter(e => !e.processTypes || (e.processTypes && e.processTypes.includes(orderItem.deliveryProcessType))).length > 0){
                orderWithEvent = orderItem
                break
            }
        }
        return orderWithEvent
    }


    const resetSelect = () => {
        setSelectedOrders([])
    }
    useEffect(() => {
        setStatus(null)
    }, [selectedOrders])
    
    useEffect(() => {
        refreshSelectedOrders()
    }, [refreshSelectedOrders])

    return (
        <>
            {data && data.orders?.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <AntPagination controlPage={{...controlPage, totalPages: data.totalPages, isLast: data.isLast, left: data.left, total: data.total}} />
                    {
                        (selectedOrders?.length > 0 && hasAnyRole([Roles.Admin, Roles.Manager])) && (<>
                            <div className="d-flex flex-column align-items-center">
                                <p className="btn-text m-0">{t('ordersPage.selectBar.selected')} {selectedOrders?.length}</p>
                                <p className="btn-text m-0 text-primary cursor" onClick={resetSelect}>{t('ordersPage.selectBar.abortAll')}</p>
                            </div>
                            <div className="d-flex">
                                <OrderProviderSelect
                                  selectedOrders={selectedOrders}
                                  refetch={() => {
                                      onEdit()
                                      refreshSelectedOrders()
                                  }}
                                />
                                <SearchableSelect
                                    orderItem={selectedOrders[0]}
                                    menuPlacement={'top'}
                                    placeHolderOnly={true}
                                    readOnly={!(selectedOrders.length > 0)}
                                    onChange={handleCouriersSearch}
                                />
                                <RouteCellSelect
                                    items={cellOptions}
                                    disabled={false}
                                    defaultItem={"Ячейка"}
                                    onChange={handleCellChange}
                                    menuPlacement={'top'}
                                    className={"mx-2"}
                                />

                                  <div className='position-relative z-3'>

                                      {status !== null && (
                                        <ConfirmModal
                                          onCancel={() => {
                                              setStatus(null);
                                          }}
                                          onSuccess={() => handleChangeStatuses(status)}
                                          isLoading={isSelectBarLoading}
                                          styles={{ bottom: '120%', right: '-30%', minWidth: '600px' }}
                                        >{status?.name}</ConfirmModal>
                                      )}
                                      <Dropdown className={'mx-1'}>
                                          <Dropdown.Toggle
                                            className='w-100 border-rounded-1'
                                            id={'deliveryType-dropdown'}
                                            variant={'primary'}
                                            disabled={!getOrderWithEvent(selectedOrders)}
                                          >
                                              Изменить статус
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className='p-0'>
                                              {orderStatusMap[getOrderWithEvent(selectedOrders)?.status]?.events
                                                .filter(e => !e.processTypes || (e.processTypes && e.processTypes.includes(getOrderWithEvent(selectedOrders).deliveryProcessType)))
                                                .map(e => (
                                                  <Dropdown.Item
                                                    key={getOrderWithEvent(selectedOrders)?.id}
                                                    className='w-100 text-center'
                                                    onClick={() => setStatus(e)}
                                                  >
                                                      {t(`orderEvents.${e.event}`)}
                                                  </Dropdown.Item>
                                                ))}
                                          </Dropdown.Menu>
                                      </Dropdown>
                                  </div>




                                <Dropdown className={"mx-1"}>
                                    <Dropdown.Toggle
                                        variant={"primary"}
                                        className="border-rounded-1 border-dark border-opacity-25"
                                        disabled={!(selectedOrders.length > 0)}
                                    >
                                        {t('ordersPage.selectBar.buttons.print')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-0 mb-1">
                                        <Dropdown.Item
                                            className="py-2 border-rounded-1 text-center"
                                            onClick={handlePrintStickers}
                                        >
                                            {t('ordersPage.selectBar.buttons.printStickers')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="py-2 border-rounded-1 text-center"
                                            onClick={handlePrintCouriers}
                                        >
                                            {t('ordersPage.selectBar.buttons.printCouriers')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="py-2 border-rounded-1 text-center"
                                            onClick={handlePrintNotes}
                                        >
                                            {t('ordersPage.selectBar.buttons.printNotes')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </>)
                    }
                </div>
            )}
        </>
    );
})

export default SelectBar;