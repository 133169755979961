import { OrderItem } from '@/common/models/order';
import { buildDeliveryPartyString, convertToTimeZone } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';

import {t} from 'i18next';

export const printCouriers = ({orderItems, openNotification}) => {
  const hasCourier = orderItems.every((orderItem: OrderItem) => orderItem.courier)

  if (!hasCourier) {
    return openNotification('error', `${t('ordersPage.selectBar.alerts.fail.selectOrdersWithAssignedCouriers')}`)
  }

  const formatedSelectedOrders: OrderItem[] = JSON.parse(JSON.stringify(orderItems));
  const estimatedDeliveryDates = {};
  const groupedOrderItems = formatedSelectedOrders.reduce((acc, order: OrderItem) => {
    const courierKey = `${order.courier.firstName} ${order.courier.lastName}`

    if (!acc[courierKey]) {
      acc[courierKey] = []
    }

    if(!estimatedDeliveryDates[courierKey]) {
      estimatedDeliveryDates[courierKey] = [];
    }
    estimatedDeliveryDates[courierKey].push(convertToTimeZone(order?.estimatedDeliveryDate, false, Formats.DATE_DDMMMMYYYY, true) || null)

    acc[courierKey].push([
      buildDeliveryPartyString(order.deliveryReceiver),
      '', '', '',
      convertToTimeZone(order?.createDate, false, Formats.DATE_DMY_DOT),
    ]);

    return acc
  }, {} as Record<string, OrderItem[]>);
  const headers = [
    '№',
    t('deliveryParty.receiver'),
    t('pages.orderNotePrint.fullName'),
    t('pages.orderNotePrint.workPosition'),
    t('pages.orderNotePrint.signature'),
    t('ordersImportPage.table.columns.createDate'),
  ]
  const wb = XLSX.utils.book_new();

  Object.keys(groupedOrderItems).forEach((courier) => {
    const estimatedDeliveryDate = estimatedDeliveryDates[courier].every(date => estimatedDeliveryDates[courier][0] === date) ? estimatedDeliveryDates[courier][0] : null;

    const ws = XLSX.utils.aoa_to_sheet([
      ['', new Date().toLocaleDateString()],
      ['', `${t('courierExport.accepted')}: ${groupedOrderItems[courier].length}`, '', '', '', ''],
      ['', courier, '', '', `${t('ordersPage.stickers.route')}`],
      ['', `${t('courierExport.estimatedDeliveryDate', {date: estimatedDeliveryDate ? estimatedDeliveryDate : ''})}`, '', '', '____'],
      headers,
      ...groupedOrderItems[courier].map((item, i) =>
        [i + 1].concat(item))]);

    const range = XLSX.utils.decode_range(ws['!ref']);

    for (let R = range.s.r; R <= range.e.r; R++) {
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[cellAddress]) continue;
        if (!ws[cellAddress].s) ws[cellAddress].s = {};
        ws[cellAddress].s.alignment = {
          wrapText: true,
          vertical: "center",
          horizontal: "left"
        };
      }
    }

    ws['!cols'] = [
      { wch: 3 },
      { wch: 40 },
      { wch: 30 },
      { wch: 12 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
    ]

    XLSX.utils.book_append_sheet(
      wb,
      ws,
      courier);
  } );

  const exportFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const url = URL.createObjectURL(new Blob([exportFile], { type: 'application/octet-stream' }));
  const now = new Date();
  const a = document.createElement('a');
  a.href = url;
  a.download = t('courierExport.fileName',
    {export_date: format(now, Formats.EXPORT_TIMESTAMP)}) // File name
  a.click();

  URL.revokeObjectURL(url);
  a.remove();
}
