import React from 'react';
import { DatePicker } from 'antd';
import AntText from '@/components/controls/text/antText';
import s from './index.module.scss';

const AntDatePicker = ({error, success, ...props}) => {

  return (
    <>
      <DatePicker status={error ? 'error' : ''} {...props} rootClassName={s['custom-ant-picker']}/>
      {error && <AntText size={'small'} weight={'medium'} color={'accentRed'} style={{whiteSpace: 'nowrap'}}>{error}</AntText>}
      {success && <AntText size={'small'} weight={'medium'} color={'accentGreen'}>{success}</AntText>}
    </>
  );
};

export default AntDatePicker;