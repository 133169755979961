
export enum QrType {
    ORDER_ITEM = 'ORDER_ITEM',
    COURIER = 'COURIER',
    CELL = 'CELL',
}

export interface OrderItemQrValue {
    type: QrType,
    id: number,
    orderId: number,
}

export interface CourierQrValue {
    type: QrType,
    id: number,
    firstName: string,
    lastName: string,
}

export interface CellQrValue {
    type: QrType,
    id: number,
    name: string,
}
