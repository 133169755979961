import React, {useState} from 'react';
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import { useTranslation } from 'react-i18next';


import {OrderSearchQuery} from "@/common/models/order";
import useOrderSearch from "@/common/hooks/useOrderSearch";
import {deliveryStatusOptions} from "@/pages/order/options";

const OrdersTabs = ({onChange}) => {
    const { persistedFilterQuery }: OrderSearchQuery = useOrderSearch()
    const { t } = useTranslation()
    const {deliveryStatuses} = persistedFilterQuery
    const [ordersViewMode, setOrdersViewMode] = useState(getInitialMode(`${deliveryStatusOptions.find(option => option.value === "*").query}`,
        `${deliveryStatusOptions.find(option => option.value === "DELIVERED").query},${deliveryStatusOptions.find(option => option.value === "NON_DELIVERED").query}`));
    const {persistSortOptions} = useOrderSearch()

    function getInitialMode (activeStatuses, resolvedStatuses) {
        if(deliveryStatuses === activeStatuses)
            return "active"
        else if(deliveryStatuses === resolvedStatuses)
            return "delivered"
        else
            return "filtered"
    }
    const handleModeChange = (value) => {
        persistSortOptions([])
        setOrdersViewMode(value);
        if(value === "active") {
            onChange({
                ...persistedFilterQuery,
                deliveryStatuses: deliveryStatusOptions.find(option => option.value === "*").query
            })
        } else {
            onChange({
                ...persistedFilterQuery,
                deliveryStatuses: `${deliveryStatusOptions.find(option => option.value === "DELIVERED").query},${deliveryStatusOptions.find(option => option.value === "NON_DELIVERED").query}`
            })
        }
    };

    return (
        <ToggleButtonGroup
            type="radio"
            name="options"
            value={ordersViewMode}
            onChange={handleModeChange}
        >
            <ToggleButton className={ordersViewMode !== "active" ? "bg-light" : "bg-primary"} id="active-orders-tbg" value={'active'}>
                <span className={ordersViewMode !== "active" ? "text-dark" : "text-light"}>{t('ordersPage.tabs.active')}</span>
            </ToggleButton>
            <ToggleButton className={ordersViewMode !== "delivered" ? "bg-light" : "bg-primary"} id="delivered-orders-tbg"
                          value={'delivered'}>
                <span className={ordersViewMode !== "delivered" ? "text-dark" : "text-light"}>{t('ordersPage.tabs.archive')}</span>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default OrdersTabs;