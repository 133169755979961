export const ruCommon = {
  "api": {
    "messages": {
      "serviceDown": "Сервис недоступен",
      "loading": "Загрузка",
      "error": "Ошибка"
    }
  },
  "deliveryProcessTypes": {
    "HH": "HeadHunter",
    "Alseko": "Алсеко",
    "Qazpost": "Казпочта",
    "CafeSoft": "Кафе Софт",
    'title': 'Способ доставки',
  },
  "cities": {
    "Almaty": "Алматы"
  },
  "sidebar": {
    "home": "Главная",
    "orders": "Заказы",
    "scanner": "Сканировать через QR",
    "couriers": "Курьеры",
    "clients": "Справочник клиентов",
    "reports": "Отчёты",
    "updateData": "Обновить данные"
  },
  "roles": {
    "Manager": "Менеджер",
    "Client": "Клиент",
    "Admin": "Администратор"
  },
  "pagination" : {
    "from": "из",
    "orders": "заказов",
    "jump_to": "Перейти к",
    "page": "странице",
    "prev_page": "Предыдущая",
    "next_page": "Предыдущая",
    "total": "{{range}} из {{total}} заказов",
  },
  "orderStatus": {
    "NEW": "Новый",
    "READY_FOR_PICKUP": "Готов к забору",
    "PICKUP_COURIER_ASSIGNED": "Назначен забор на курьера",
    "ON_WAY_TO_WAREHOUSE": "В пути на склад",
    "DELIVERED_TO_WAREHOUSE": "Доставлен на склад",
    "ACCEPTED_BY_WAREHOUSE": "Принят складом по количеству",
    "MARKING_AT_WAREHOUSE": "Маркировка",
    "MARKED_AT_WAREHOUSE": "Отмаркирован",
    "SORTING_AT_WAREHOUSE": "Сортировка",
    "SORTED_AT_WAREHOUSE": "Отсортирован складом",
    "READY_FOR_DELIVERY": "Готов к доставке",
    "READY_FOR_DEPARTURE_DELIVERY": "Готов к доставке из г. {{from_city}}",
    "DELIVERY_TO_DEPARTURE": "Доставка в пункт отправки г. {{from_city}}",
    "DELIVERY_IN_TRANSIT": "В пути {{from_city}} — {{to_city}}",
    "READY_FOR_ARRIVAL_DELIVERY": "Готов к забору в г. {{to_city}}",
    "PICKUP_COURIER_ARRIVAL_ASSIGNED": "Курьер в пути за забором",
    "DELIVERY_COURIER_ARRIVAL_ASSIGNED": "Назначен курьер на доставку",
    "DELIVERY_COURIER_ASSIGNED": "Назначена доставка на курьера",
    "DELIVERY_IN_PROGRESS": "В пути на доставку",
    "DELIVERED_BY_COURIER": "Доставлен курьером (Завершен)",
    "DELIVERED_ACCEPTED_MANAGER": "Доставка подтверждена менеджером (Завершен)",
    "PENDING_CLIENT_CLARIFICATION": "Ожидает уточнения у Клиента",
    "WAREHOUSE_DELIVERY_RETRY_ON_COURIER_FAULT": "Не успел",
    "WAREHOUSE_DELIVERY_RETRY_ON_CLIENT_UNAVAILABLE": "Нет клиента на месте",
    "WAREHOUSE_DELIVERY_RETRY_ON_INVALID_ADDRESS": "Неверный адрес",
    "UNABLE_TO_DELIVER": "Невозможно доставить (Завершено)",
    "WAREHOUSE_DELIVERY_REPEATED": "Отказ от посылки (Завершено)",
    "DELIVERY_DESTROYED": "Уничтожен (Завершено)",
    "DELIVERY_RETURNED_TO_SENDER": "Возвращен отправителю (Завершено)"
  },
  "orderEvents": {
    "ASSIGN_PICKUP_COURIER": "Курьер назначен",
    "WAREHOUSE_ACCEPT": "Принят складом по количеству",
    "WAREHOUSE_DELIVERY_REPEATED": "Доставка не дошла до получателя",
    "WAREHOUSE_BEGIN_MARKING": "Начать маркировку",
    "WAREHOUSE_FINISH_MARKING": "Завершить маркировку",
    "WAREHOUSE_BEGIN_SORTING": "Начать сортировку",
    "WAREHOUSE_FINISH_SORTING": "Завершить сортировку",
    "WAREHOUSE_CONFIRM_DELIVERY": "Готово к доставке",
    "MANAGER_CONFIRM_ARRIVAL": "Готов к забору",
    "ASSIGN_PICKUP_ARRIVAL_COURIER": "Назначен курьер на забор",
    "WAREHOUSE_ASSIGN_DELIVERY_COURIER": "Назначен курьер на отправление",
    "MANAGER_CONFIRM_DELIVERY": "Подтвердить доставку",
    "WAREHOUSE_DELIVERY_RETRY_ON_COURIER_FAULT": "Курьер не успел",
    "WAREHOUSE_DELIVERY_RETRY_ON_CLIENT_UNAVAILABLE": "Клиента не было на месте",
    "WAREHOUSE_DELIVERY_RETRY_ON_INVALID_ADDRESS": "Неправильный адрес",
    "WAREHOUSE_DELIVERY_ABORTED_DESTROY": "Отправить на уничтожение",
    "WAREHOUSE_DELIVERY_ABORTED_RETURN": "Вернуть отправителю",
    "DELIVERY_ABORTED_RETURN_TO_SENDER": "Возврат отправителю (Завершено)",
    "MOVE_TO_WAREHOUSE": "В путь",
    "WAREHOUSE_ARRIVED": "Прибыл на склад",
    "DELIVER_TO_RECEIVER": "В путь",
    "COURIER_CONFIRM_DEPARTURE_DELIVERY": "Доставлен в пункт отправки",
    "UNABLE_TO_DELIVER": "Невозможно доставить",
    "COURIER_CONFIRM_DELIVERY": "Доставлено",
    "COURIER_PICKUP_ARRIVAL": "Забор получен",
    "WAREHOUSE_PASS_TO_THIRD_PARTY_PROVIDER": "Отправлено в Казпочту"
  },
  "deliveryType": {
    "STANDARD": "Обычный",
    "URGENT": "Срочный"
  },
  "parcelType": {
    "ENVELOPE": "Корреспонденция",
    "SMALL_PACKAGE": "Мелкие",
    "LARGE_PACKAGE": "Крупногабаритные"
  },
  "deliveryParty": {
    "sender": "Отправитель",
    "receiver": "Получатель"
  },
  "courierTabs": {
    "delivery": "Доставка",
    "pickup": "Забор"
  },
  "status": {
    "confirm": "Вы действительно хотите поменять статус на"
  },
  "pages": {
    "orderNotePrint": {
      "orderOwner": "Заказчик",
      "orderCreateDate": "Дата заказа",
      "orderItemId": "№ заказа",
      "deliveredCourier": "Доставил курьер",
      "receivedBy": "Получил",
      "fullName": "ФИО",
      "workPosition": "Должность",
      "phone": "Телефон",
      "dateTime": "Дата/время",
      "signature": "Подпись",
      "companyName": "Компания",
      "city": "Город",
      "address": "адрес",
      "pickup": "Забрать",
      "deliver": "Доставить",
      "noData": "Нет данных"
    }
  },
  "orderExportPage": {
      "noDataError": "Отсутствуют заказы для экспорта",
      "serverError": "Ошибка сервера при выгрузке заказов",
      "sheet": "Лист 1",
      "fileName": "Заказы_{{export_date}}.xlsx"
  },
  'courierExport': {
    fileName: 'Реестр_курьеров_{{export_date}}.xlsx',
    issued: 'Выдана',
    accepted: 'Принял',
    courierSignature: 'Подпись курьера',
    estimatedDeliveryDate: 'Ожидаемая дата доставки: {{date}}'
  },
  "attachmentPage": {
      "noData": "В этом заказе нет вложений",
      "header": "Вложения заказа от {{firstName}} {{lastName}}",
      "deletePopup": {
          "title": "Удаление вложения {{id}}",
          "description": "Подтвердите удаление вложения",
          "buttons": {
              "confirmBtn": "Подтвердить",
              "cancelBtn": "Отменить"
          }
      }
  },
}