import React from 'react'
import { BoxArrowRight } from 'react-bootstrap-icons';
import i18n from 'i18next'


import {Roles} from "@/common/constants/roles";
import { User } from '@/common/models/user'

const t = i18n.t

const UserProfile = ({ showNav, user, onSignOut }: UserProfileProps) => {
    if (!user) {
        return
    }

    return (
        <div className="d-flex justify-content-around bg-light py-2 border-rounded-2">
            {(!showNav) && (
              <div className="user-card">
                  <span className='fw-medium text-muted'>{user.username}</span>
                  <div>
                      {user.roles.map((role, index) => (
                        <span key={index} className=" fw-medium">
                            {roleLabels[role]}
                        </span>
                      ))}
                  </div>
              </div>
            )}
            <button className="logout btn btn-outline-danger"
                 style={{ left: `${showNav ? '0' : '1.5vw'}`}}
                 onClick={onSignOut}
            >
                <BoxArrowRight
                  id="logout"
                  className="fas"
                  style={{ left: `${showNav ? '0' : '-0.4vw'}`}}
                />
            </button>
        </div>
    )
}

const roleLabels = {
    [Roles.Manager]: t('roles.Manager'),
    [Roles.Client]: t('roles.Client'),
    [Roles.Admin]: t('roles.Admin'),
}

interface UserProfileProps {
    showNav: boolean,
    user: User,
    onSignOut: () => void,
}

export default UserProfile