export class RegexPatterns {
    static readonly names = /^[A-Za-zА-Яа-яЁёӘәҒғҚқҢңӨөҰұҮүҺһІіҢң0-9]+$/

    /**
     * @deprecated Phone mask is introduced. No need to check for country code
     */
    static readonly phones = /^(\+?7)\d{11}$/

    static readonly phoneSize = /^\d{11}$/
    static readonly companyNames = /^[A-Za-z0-9\s\-&'.,\u0400-\u04FF\u0500-\u052F\u2DE0-\u2DFF\uA640-\uA69F]+$/
    static readonly iin = /^\d{12}$/
    static readonly bin = /^\d{12}$/
    static readonly RUSSIAN_CYRILLIC_REGEX = /[а-яА-ЯёЁ]/g;
    static readonly redundantCharacters = /[^a-zA-Z0-9:{}[\]",._-]/g
}