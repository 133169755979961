import React from 'react'
import { NavLink } from 'react-router-dom'

import '../Sidebar.scss'

const NavItem = ({ currentPath, toPath, icon, text, defaultChoice = false, show = true , showNav}: NavItemProps) => {

    const isOnPath = currentPath === toPath || defaultChoice;

    return !show ? <></> : (
        <NavLink
            className={`w-100 text-decor-none p-1 ${(isOnPath) ? 'active text-dark' : 'text-dark'}`}
            style={ isOnPath ?{
                backgroundColor: '#F0EEFA',
                borderLeft: '4px',
                borderColor: '#7B57DF',
        }: {}}
            to={toPath}
        >
            <div className="d-flex align-items-center py-1">
                {icon}
                <span className="fw-medium" style={{
                  color: isOnPath ?'#7B57DF' : 'black',
                  display: showNav && 'none',
                  marginLeft: '10px',
                  width: '70%',
                }} >{text}</span>
            </div>
        </NavLink>
    )
}

interface NavItemProps {
    currentPath: string,
    toPath: string,
    icon: React.ReactNode,
    text: string,
    defaultChoice?: boolean,
    show?: boolean,
    showNav: boolean,
}

export default NavItem