import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Badge, Spinner } from "react-bootstrap"
import { useMutation } from "react-query"
import WebApp from "@twa-dev/sdk"
import { ResponseError } from "superagent"
import { useNavigate } from 'react-router-dom';

import { Order } from "@/common/models/order"
import useAlert from "@/common/hooks/useAlert"
import { Formats } from "@/common/constants/dateFormat"
import { convertToTimeZone } from "@/common/utils/utils"
import OrderEventActionBar from "@/telegram/components/event-actionbar/OrderEventActionBar"
import OrderProperty from "@/telegram/components/OrderProperty"
import PhoneLink from "@/telegram/components/PhoneLink"
import OrderService from "@/telegram/api/OrderService"
import actions from '@/telegram/components/event-actionbar/actions';

type VariablesT = { orderItemIds: number[]; event: string }

const OrderActionCard: FC<OrderActionCardProps> = ({ data }) => {
    const { t } = useTranslation()
    const { openNotification } = useAlert()

    const orderItemIds = data?.orderItems?.map(oi => oi.id)
    const [currentStatus, setCurrentStatus] = useState<string>(data?.status)
    const { showPopup } = WebApp
    const navigate = useNavigate()

    const { mutate: sendStatusChange, isLoading } = useMutation<string, ResponseError, VariablesT>(
        ({ orderItemIds, event }) => OrderService.batchChangeStatus(orderItemIds, event), 
        {
            onSuccess: (newStatus) => {
                setCurrentStatus(newStatus);
                if(newStatus && !actions[newStatus]){
                    navigate('/telegram/courier')
                }
            },
            onError: (error) => openNotification('error', error?.response?.body?.errorMessage || t('api.messages.serviceDown')),
        })

    const handleActionClick = (event: string) => {
        showPopup(
            {
                message: t('telegram.pages.orderAction.confirmAction', { event: t(`orderEvents.${event}`) }),
                buttons: [
                    { id: event, text: t('telegram.pages.orderAction.buttons.confirmBtn'), type: 'default' },
                    { type: 'cancel' }
                ]
            },
            (value) => value && value !== '' && sendStatusChange({orderItemIds, event: value })
        )
    }

    return (
        <div>
            {isLoading &&
                <Spinner />}
            <div className="d-flex mb-4">
                <div>
                    <span className="d-block heading-3">{t('telegram.pages.orderAction.order')} №{data.id}</span>
                    <span className="text-muted">{t(`orderStatus.${currentStatus}`)}</span>
                </div>
            </div>
            <div className="position-relative bg-white border border-rounded-2 p-3">
                <div className="position-absolute" style={{ top: '-12px' }}>
                    <Badge className="border" pill>
                        <span className="ms-auto heading-badge">
                            {convertToTimeZone(data?.createDate, false, Formats.DATE_DMY_TIME)}
                        </span>
                    </Badge>
                </div>
                <div>
                    <div className="mb-2">
                        <OrderProperty
                            text={t('telegram.pages.orderAction.deliverySender')}
                            value={data?.deliverySender?.company?.name} 
                        />
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text={t('telegram.pages.orderAction.deliverySenderAddress')}
                            value={data?.deliverySender?.address?.addressString}
                        />
                    </div>
                    <div className="my-2">
                        <div>
                            <span className="d-block heading-font">{data?.deliverySender?.contactName}</span>
                            <PhoneLink phone={data?.deliverySender?.phone}/>
                        </div>
                    </div>
                    <div className="mt-2">
                        <OrderProperty
                            text={t('telegram.pages.orderAction.counter.total')}
                            value={data?.orderItems.length}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <OrderEventActionBar
                    status={currentStatus}
                    onClick={handleActionClick} 
                />
            </div>
        </div>
    )
}

interface OrderActionCardProps {
    data: Order,
}

export default OrderActionCard