import {useLocation} from 'react-router-dom'
import {PencilSquare} from 'react-bootstrap-icons'
import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Spinner} from 'react-bootstrap'

import './Sidebar.scss'
import useAuth from '@/common/hooks/useAuth'
import {Roles} from '@/common/constants/roles'
import UserProfile from './components/UserCard'
import NavItem from './components/NavItem'
import SoftLogo from '@/assets/icons/logo/soft_top_logo.svg'
import { t } from 'i18next';
import logo from '@/assets/icons/logo/Logo.svg';
import textDown from '@/assets/icons/logo/Craft.svg';
import {ReactComponent as OrderLogo} from '@/assets/icons/sideBar/truck-fast.svg';
import {ReactComponent as Main} from '@/assets/icons/sideBar/home.svg';
import {ReactComponent as Reports} from '@/assets/icons/sideBar/reports.svg';
import {ReactComponent as Couriers} from '@/assets/icons/sideBar/couriersLogo.svg';
import {ReactComponent as User} from '@/assets/icons/sideBar/user.svg';
import {ReactComponent as Scan} from '@/assets/icons/sideBar/scan.svg';

const Path = [
  {label : 'sidebar.home', path: '/', icon: Main, roles: [Roles.Client]},
  {label : 'sidebar.orders', path: '/orders', icon: OrderLogo, roles: [] },
  {label : 'sidebar.scanner', path: '/scanner', icon: Scan, roles: [Roles.Manager, Roles.Admin] },
  {label : 'sidebar.couriers', path: '/couriers', icon: Couriers, roles: [Roles.Manager, Roles.Admin] },
  {label : 'sidebar.reports', path: '/reports', icon: Reports, roles: [Roles.Manager, Roles.Admin] },
  {label : 'sidebar.clients', path: '/client-handbook', icon: User, roles: [Roles.Manager, Roles.Admin] },
]

const Sidebar = observer(() => {
    const { user, signOut, hasRole, hasAnyRole, isLoading } = useAuth()
    const { pathname } = useLocation()
    const [showNav, setShowNav] = useState(true);

    return (
        <div id="nav-bar" style={{width: !showNav ? '18.125vw' : '5.7vw'}}>
            <input id="nav-toggle" type="checkbox"
                   readOnly={true}
                   checked={showNav}
            />
            <div id="nav-header">
              <div className='logo'>
                <img src={logo} className="logo-img" alt='Logo' />
                <div className={`logo-body ${showNav && 'd-hide'}`}>
                  <img src={SoftLogo} className={`textUp ${showNav && 'd-none'}`} alt='Soft' />
                  <img src={textDown} className={`textDown ${showNav && 'opacity-0'}`} alt='Craft' />
              </div>
              <label htmlFor="nav-toggle" onClick={() => setShowNav(!showNav)}>
                <span id="nav-toggle-burger"></span>
              </label>
                <hr/>
            </div>
            </div>
            <div id="nav-content" style={{marginTop: '20px'}}>
              {
                Path.map(p => {
                  const Icon = p.icon;
                  const isCurrent = pathname === p.path;
                  return (
                  <div className={`nav-button ${isCurrent && 'nav-button-active'}`}>
                    <NavItem
                      text={t(p.label)}
                      icon={<Icon style={{ color:isCurrent && '#7B57DF', marginLeft: isCurrent ? '6px' : "10px", width: '16px', height: '16px',}} />}
                        currentPath={pathname}
                        toPath={p.path}
                        show={hasAnyRole(p.roles)}
                        showNav={showNav}
                        />
                    </div>)
                  }
                )
              }
                <div id="nav-content-highlight"></div>
            </div>

            <div className="nav-footer" id="nav-footer">
                <div
                    className="d-flex flex-column justify-content-end"
                >
                    <NavItem
                        text={t('sidebar.updateData')}
                        icon={<PencilSquare className="fas"/>}
                        currentPath={pathname}
                        show={hasRole(Roles.Client)}
                        toPath="/onboard"
                        showNav={showNav}
                    />
                </div>

                {/*    /!* User *!/*/}
                    <div className="nav-button w-100"
                         style={{justifyContent: 'flex-start'}}
                    >
                        {isLoading ? (
                            <div className="d-flex align-items-center justify-content-center p-3">
                                <Spinner/>
                            </div>
                        ) : (
                            <UserProfile showNav={showNav} user={user} onSignOut={signOut}/>
                        )}
                    </div>
            </div>
        </div>
    )
})

export default Sidebar
