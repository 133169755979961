export enum Formats {
    DATE_TIMESTAMP = "yyyy-MM-dd'T'HH:mm:ss",  // Example: 2024-01-21T15:30:45
    DATE_DMY_TIME = "dd/MM/yyyy - HH:mm",      // Example: 21/01/2024 - 15:30
    DATE_DMY = "dd-MM-yyyy",                    // Example: 21-01-2024
    DATE_DMY_DOT = "dd.MM.yyyy",                 // Example: 21.01.2024
    EXPORT_TIMESTAMP = "dd.MM.yyyy_HH-mm",      // Example: 21.01.2024_15-30
    DATE_YMD = "yyyy-MM-dd",                    // Example: 2024-01-21
    DATE_DMMMY_TIME = "dd MMM, yyyy, HH:mm",    // Example: 21 Jan, 2024, 15:30
    DATE_DDMMMMYYYY = "dd MMMM yyyy",            // Example: 21 January 2024
    DATE_DDMMMYYYY_TIME = "dd MMM, yyyy, HH:mm", // Example: 21 Jan, 2024, 15:30
}

export const possibleDateFormats = [
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM/dd/yyyy',
    'yyyy/MM/dd',
    'dd/MM/yyyy',
    'yyyyMMdd',
    'ddMMyyyy',
    'yyyy-MM-dd\'T\'HH:mm:ss',
    'dd/MM/yyyy - HH:mm',
    'dd.MM.yyyy',
    'dd.MM.yyyy_HH-mm',
    'dd MMM, yyyy, HH:mm',
    'dd MMMM yyyy',
];
