import { makeAutoObservable } from 'mobx';

import {Persistable} from "../storage/persistable";
import { OrderItem } from '@/common/models/order';


class PrintStore implements Persistable {
  stickers: OrderItem[] = [];
  notes: OrderItem[] = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    this.loadFromLocalStorage()
  }


  setStickers(stickers: OrderItem[]) {
    this.stickers = stickers;
    localStorage.setItem('stickers', JSON.stringify(this.stickers));
  }

  setNotes(notes: OrderItem[]) {
    this.notes = notes;
    localStorage.setItem('notes', JSON.stringify(this.notes));
  }

  writeToLocalStorage () {
    localStorage.setItem('stickers', JSON.stringify(this.stickers))
    localStorage.setItem('notes', JSON.stringify(this.notes))
  }

  loadFromLocalStorage() {
    const stickersStr = localStorage.getItem('stickers')
    const notesStr = localStorage.getItem('notes')

    if (stickersStr) {
      const parsedStickers = JSON.parse(stickersStr)
      if (Array.isArray(parsedStickers)) {
        this.stickers = parsedStickers
      }
    }

    if (notesStr) {
      const parsedNotes = JSON.parse(notesStr)
      if (Array.isArray(parsedNotes)) {
        this.notes = parsedNotes
      }
    }
  }

  resetPrintStore() {
    this.stickers = []
    this.notes = []

    localStorage.removeItem('stickers')
    localStorage.removeItem('notes')

  }
}

const printStore = new PrintStore()

export default printStore