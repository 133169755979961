import React from "react";
import { Container, Row } from "react-bootstrap"
import { Outlet } from "react-router-dom"

import Sidebar from "./components/sidebar/Sidebar"

const Layout = () => {

    return (
        <main className="d-flex">
            <Container fluid>
                <Row className="d-flex flex-row flex-nowrap">
                    <Sidebar />
                    <div id="layout" style={{overflowY: "auto"}}>
                        <Outlet />
                    </div>
                </Row>
            </Container>
        </main>
    )
}

export default Layout
