import { useEffect, useState } from "react";

export const usePageFocusTracker = () => {
  const [isPageFocused, setIsPageFocused] = useState(true);

  const handleVisibilityChange = () => {
    setIsPageFocused(!document.hidden);
  };

  const handleFocus = () => {
    setIsPageFocused(true);
  };

  const handleBlur = () => {
    setIsPageFocused(false);
  };

  useEffect(() => {
    // Listen for visibility changes (e.g., tab changes)
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Listen for focus and blur events
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return isPageFocused;
};
