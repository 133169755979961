import { notification, NotificationArgsProps } from "antd"

type NotificationPlacement = NotificationArgsProps['placement'];
type NotificationType = NotificationArgsProps['type']

type UseAlert = {
  openNotification: (type: NotificationType, message: string, description?: string, duration?: number, key?: string) => void,
  destroyNotification: (key: string) => void
}

const useAlert = (): UseAlert => {
    const defaultPlacement: NotificationPlacement = 'topRight'
    // If duration is 0, notification never disappear

    const openNotification = (type: NotificationType = 'error', message: string, description?: string, duration: number = 4.5, key: string = null) => {
      notification[type]({
        ...(key ? {key: key} : {}),
        message: message,
        description: description,
        placement: defaultPlacement,
        duration: duration,
      });
    };

  return {
    openNotification, destroyNotification: (key: string) => notification.destroy(key)
  }
}

export default useAlert