import React, {forwardRef, useEffect, useRef} from 'react'
import {useReactToPrint} from 'react-to-print'
import QRCode from 'react-qr-code'
import { observer } from 'mobx-react-lite';

import './index.css'
import { OrderItem } from '@/common/models/order'
import { convertToTimeZone } from '@/common/utils/utils'
import { Formats } from '@/common/constants/dateFormat'
import { useTranslation } from 'react-i18next';
import { getOrderItemQrValue } from '@/common/utils/qrUtils'
import { usePrint } from '@/common/hooks/usePrint';

export const StickerListPrintPage = observer(() => {
    const { stickers: orders } = usePrint()
    const stickersContainerRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => stickersContainerRef.current,
        pageStyle: `
          @media print {
            .body-sticker {
              page-break-after: always;
            }
          }
        `,
    })
    useEffect(() => {
        handlePrint()
    }, [handlePrint])

    return (
        <div>
            <div ref={stickersContainerRef} className="d-flex flex-column font-sticker">
                {
                    [...orders].sort((a, b) => a?.orderNumber - b?.orderNumber).map(order => {
                            return <div key={order?.id}>
                                <OrderPrintItem order={order} />
                            </div>
                        }
                    )
                }
                <button className={"d-none"} id="stickers-print" onClick={handlePrint}></button>
            </div>
        </div>


    )
})


const OrderPrintItem = forwardRef<HTMLDivElement, OrderPrintItemProps>(({order}, ref) => {
    const {t} = useTranslation()
    if (!order) {
        return <div>No order details available.</div>
    }
    const qrValue: string = getOrderItemQrValue(order)
    const getAddressWithStartBold = (address: string) => {
        const [firstPart, ...rest] = address.split(/,(.+)/)
        return (
            <span>
                <span className="font-bold">{firstPart}, </span>
                {rest}
            </span>
        )
    }

    return (
        <div className="d-flex flex-column justify-content-between body-sticker" ref={ref}>
            <div className="d-flex">
                <div className="field-section space-right">
                    <div className="font-sticker-header">
                        <span>{t('ordersPage.stickers.sender')}</span>
                    </div>
                    <div className="delivery-party-info">
                        <div>
                            <span>{order.deliverySender.company.name}</span>
                        </div>
                        <div className="field">
                            {getAddressWithStartBold(order.deliverySender.address.addressString)}
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <span>{order.deliverySender.contactName && `${order.deliverySender.contactName}, `}{order.deliverySender.phone}</span>
                        </div>
                    </div>
                </div>
                <div className="field-section">
                    <div className="font-sticker-header">
                        <span>{t('ordersPage.stickers.receiver')}</span>
                    </div>
                    <div className="delivery-party-info">
                        <div>
                            <span>{order.deliveryReceiver.company.name}</span>
                        </div>
                        <div className="field">
                            {getAddressWithStartBold(order.deliveryReceiver.address.addressString)}
                        </div>
                        <div className="field">
                            <span>{order.deliveryReceiver.contactName && `${order.deliveryReceiver.contactName}, `}{order.deliveryReceiver.phone}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="delivery-field-section">
                    <div className="d-flex">
                        <div className="field-section space-right">
                            <div className="font-sticker-header">
                                <span>{t('ordersPage.stickers.id')}</span>
                            </div>
                            <span>{order.id}</span>
                        </div>
                        <div className="field-section">
                            <div className="font-sticker-header">
                                <span>{t('ordersPage.stickers.orderId')}</span>
                            </div>
                            <span>{order.orderId}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{ marginTop: '15px', width: '70%' }}>
                            <div className="field-section w-100">
                                <div className="font-sticker-header">
                                    <span>{t('ordersPage.stickers.createDate')}</span>
                                </div>
                                <div>
                                    <span>{convertToTimeZone(order.createDate, false, Formats.DATE_DMY_TIME)}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <div className="field-section">
                                <div className="font-sticker-header">
                                    <span style={{whiteSpace: 'nowrap'}}>{t('ordersPage.stickers.orderNumber')}</span>
                                </div>
                                <div>
                                    <span>{order?.orderNumber}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex" style={{ marginTop: '17px' }}>
                    <div className="font-sticker-section">
                        <QRCode
                            className="ms-auto"
                            size={100}
                            value={qrValue}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <div className="d-flex align-items-end justify-content-end w-100">
                        <div>
                            <div className="d-flex justify-content-end font-sticker-header" style={{ marginBottom: '15px' }}>
                                <span>{t('ordersPage.stickers.route')}</span>
                            </div>
                            <span className="font-sticker-route">{
                                order.route !== "OUT_OF_SUPPORTED_ROUTES" ? (
                                  order.route
                                ) : (
                                  t('ordersPage.stickers.unsupportedRoute')
                                )

                            }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

interface OrderPrintItemProps {
    order: OrderItem,
    ref: HTMLDivElement
}

export default StickerListPrintPage