import { StylesConfig  } from 'react-select';
import { Spinner } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import CourierService from "@/common/api/CourierService";
import { OrderItem } from "@/common/models/order";
import { SelectOption } from "@/common/models/util";
import AntText from '@/components/controls/text/antText';

const SearchableSelect = ({
                            onChange = () => {},
                            isLoading,
                            placeHolder = "",
                            placeHolderOnly = false,
                            menuPlacement = "auto",
                            readOnly = true,
                            showCourierDelivered = false,
                            orderItem,
                            error,
                            success,
                            styles = {},
                          }: SearchableSelectProps) => {
  const buildSelectOption = (item) => ({
    value: item,
    label: `${item?.lastName} ${item?.firstName}`,
  });

  const defaultSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: error ? "#EB5769" : success ? "#5DC983" : provided.borderColor,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    })
  }

  const defaultOption = {value: "", label: "Выберите курьера..."}

  const loadOptions = async (_search: any, _loadedOptions: any, { page }) => {
    const response = await CourierService.getAll({ page });
    return {
      options: response.couriers.map(buildSelectOption),
      hasMore: !response.isLast,
      additional: {
        page: page + 1,
      },
    };
  };

  const getCourierValue = (orderItem: OrderItem) => {
    if (placeHolderOnly) {
        return defaultOption
    }

    if (orderItem?.courier) {
        return buildSelectOption(orderItem.courier)
    }

    if (showCourierDelivered && orderItem?.courierDelivered) {
        return buildSelectOption(orderItem.courierDelivered)
    } else {
        return defaultOption
    }
  }

	if (isLoading) {
    	return (
        	<div className="d-flex flex-column justify-content-center align-items-center h-100">
            	<Spinner />
                <span className="heading-2 my-3">Получение ...</span>
            </div>
        )
    }

	return (
    <div>
    <AsyncPaginate
          classNamePrefix="select"
          defaultOptions
          success={success}
          error={error}
          loadOptions={loadOptions}
          className="basic-single"
          menuPlacement={menuPlacement}
          defaultValue={getCourierValue(orderItem)}
          placeholder={placeHolder || "Выберите курьера"}
          isDisabled={readOnly}
          isLoading={false}
          isClearable={false}
          isSearchable={true}
          name="courier"
          onChange={onChange}
          additional={{
              page: 0,
          }}
          styles={{
            ...defaultSelectStyles,
            ...styles,
          }}
      />
      {error && <AntText size={'small'} weight={'medium'} color={'accentRed'} style={{whiteSpace: 'nowrap'}}>{error}</AntText>}
      {success && <AntText size={'small'} weight={'medium'} color={'accentGreen'}>{success}</AntText>}
    </div>
  );
}

interface SearchableSelectProps {
    isLoading?: boolean,
    readOnly?: boolean,
    showCourierDelivered?: boolean
    orderItem: OrderItem,
    placeHolder?: string,
    placeHolderOnly?: boolean,
    menuPlacement?: 'auto' | 'top',
    error?: string;
    success?: string;
    onChange: (selectedOption: string | SelectOption) => void,
    styles: StylesConfig
}
export default SearchableSelect;
