import { ruOrdersImport } from '@/common/constants/locales/ru/ordersImport';
import { ruOptions } from '@/common/constants/locales/ru/options';
import { ruOrdersPage } from '@/common/constants/locales/ru/ordersPage';
import { ruCommon } from '@/common/constants/locales/ru/common';
import { ruTelegram } from '@/common/constants/locales/ru/telegram';
import { ruReports } from '@/common/constants/locales/ru/reports';
import { ruOrderCreate } from '@/common/constants/locales/ru/orderCreate';
import { couriersPage } from '@/common/constants/locales/ru/couriersPage';
import { authPage } from '@/common/constants/locales/ru/auth';
import { clientHandbookPage } from '@/common/constants/locales/ru/clientHandbook';
import { ruScannerPage } from '@/common/constants/locales/ru/scannerPage';

export const ruTranslation = {
  translation: {
    ...ruCommon,
    clientHandbookPage: clientHandbookPage,
    orderCreate: ruOrderCreate,
    scannerPage: ruScannerPage,
    ordersImportPage: ruOrdersImport,
    options: ruOptions,
    ordersPage: ruOrdersPage,
    telegram: ruTelegram,
    reportPage: ruReports,
    couriersPage: couriersPage,
    authPage: authPage
  },
};