import { useEffect, useRef } from "react"
import { useReactToPrint } from "react-to-print"

import './index.css'
import OrderNote from "./components/OrderNote"
import { usePrint } from '@/common/hooks/usePrint';

const OrderNotePrintPage = () => {
    const componentRef = useRef()
    const { notes: orderItems } = usePrint()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
          @media print {
            .note-content {
              page-break-after: always;
            }
          }
        `,
    })

    useEffect(() => {
        handlePrint()
    })

    return (
        <div ref={componentRef}>
            {orderItems.map((oi => (
                <div className="note-content">
                    <div className="h-50">
                        <OrderNote data={oi} />
                    </div>
                    <div className="delimiter" />
                    <div className="h-50" style={{ marginTop: '15px' }}>
                        <OrderNote data={oi} />
                    </div>
                </div>
            )))}
        </div>
    )
}

export default OrderNotePrintPage
