import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/es/form';

import { SelectOption } from '@/common/models/util';
import { Address, City } from '@/common/models/address';
import AddressAsyncSelect from '@/pages/onboard/components/AddressAsyncSelect';
import CityAsyncSelect from '@/components/controls/select/CityAsyncSelect';
import PhoneInputWithoutFormik from '@/components/controls/input/PhoneInputWithoutFormik';
import { Form, Input } from 'antd';
import { TableOrder } from '@/pages/order-import/components/OrderImportTable';

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
                                                                              editing,
                                                                              dataIndex,
                                                                              title,
                                                                              record,
                                                                              cities,
                                                                              fetchCities,
                                                                              form,
                                                                              setStateFullFields,
                                                                              setValidationStatus,
                                                                              setData,
                                                                              children,
                                                                              ...restProps
                                                                            }) => {
  const {t} = useTranslation()
  const rules = (() => {
    const validator = {
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject(new Error('')),
    }
    if (dataIndex === 'addressComments' || dataIndex === 'phone') {
      return [];
    }

    if (dataIndex === 'city') {
      return [
        {
          required: true,
          message: t('ordersImportPage.table.cityError'),
        },
        validator
        ,
      ];
    }

    if (dataIndex === 'addressString') {
      return [
        {
          required: true,
          message: '',
        },
        validator
      ];
    }

    // Default required rule for all other fields
    return [
      {
        required: true,
        message: t('ordersImportPage.table.emptyField', { title }),
      },
      validator
    ];
  })();
  const [inputValue, setInputValue] = useState<string>((record && dataIndex) ? record[dataIndex] : '');
  const city = cities?.find(city => city?.name === form?.getFieldValue('city'))
  const handleCityChange = async (option: SelectOption) => {
    form.setFieldValue('city', option.label);
    form.setFieldValue('addressString', "");
    setStateFullFields({
      city: {
        id: option?.value,
        name: option?.label,
      },
      address: ""
    })
    await handleBlur()
  }
  useEffect(() => {
    form?.validateFields()
  }, [form])

  const handleAddressChange = async (address: Address) => {
    const newAddress = `${address?.street || ""} ${address?.building || ""}`
    form.setFieldsValue({
      ...form.getFieldsValue(),
      addressString: newAddress,
      city: city?.name
    });
    form.getFieldValue('addressString') && setStateFullFields(prev => ({
      ...prev,
      address: address
    }))
    await handleBlur()
  }

  const handleBlur = async () => {
    try {
      await form.validateFields([dataIndex]);
      setValidationStatus(record.key, true); // Valid
    } catch (error) {
      setValidationStatus(record.key, false); // Invalid
    }
  };
  const getInput = (dataIndex) => {

    switch (dataIndex) {
      case 'addressString':
        return <AddressAsyncSelect
          customStyles={{
            fontSize: '0.8rem'
          }}
          prevAddress={{
            city: city || null,
            street: form?.getFieldValue('addressString'),
            building: '',
          }}
          onSelect={handleAddressChange}
          onInputChange={(street, building) => {
            form.setFieldValue('addressString', street && building ? `${street} ${building}` : undefined)
            form?.validateFields()
          }}
        />
      case 'city':
        return <div style={{ width: '100%' }}>
          <CityAsyncSelect
            key={city}
            form={form}
            addableOptions={true}
            fetchCities={fetchCities}
            customStyles={{
              fontSize: '0.8rem'
            }}
            onChange={handleCityChange}
            defaultValue={{ label: city?.name, value: city?.id }}
          />
        </div>;
      case 'phone':
        return <PhoneInputWithoutFormik style={{fontSize: '0.8rem'}} required={false} initialValue={record?.phone} label={""} id={record?.id} name={dataIndex} onMaskChange={phone => setInputValue(phone)}/>
      default:
        return <Input
          onBlur={async () => {await handleBlur()}}
          className={'import-cell'} value={inputValue}
          onChange={e => setInputValue(e?.target?.value)}
          placeholder={record[dataIndex]}
        />
    }
  };
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, width: '100%' }}
          rules={rules}
        >
          {getInput(dataIndex)}

        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  cities: City[];
  fetchCities: () => void,
  form: FormInstance,
  setStateFullFields: React.Dispatch<React.SetStateAction<{city: any;address: any;}>>;
  setValidationStatus: (key: string, validated: boolean) => void,
  setData: (value: (prev) => string[] | number []) => void;
  record: TableOrder;
  index: number;
}




export default EditableCell;