export const ruOptions = {
  searchOptions: {
    "orderItemId": "Номер заказа",
    "orderNumber": "Номер накладной",
    "deliverySenderName": "Отправитель",
    "deliverySenderBin": "БИН отправителя",
    "deliveryReceiverName": "Получатель",
    "deliveryReceiverBin": "БИН получателя",
    "deliveryReceiverAddress": "Адрес получателя",
    "courier": "Курьер",
    "cell": "Ячейка",
    "zone": "Маршрут"
  },
  dateRanges: {
    "forAll": "Все даты",
    "forToday": "Сегодня",
    "forWeek": "За неделю",
    "forMonth": "За месяц",
    "forYear": "За год",
    "customRange": "Выбрать период"
  },
  orderStatus: {
    "LABEL": "Статус доставки",
    "RESET": "Сбросить",
    "loadingHistory": "Получение истории измений...",
    "deliveredBy": "Сообщение от курьера",
    "noChanges": "Нет истории измений",
    "somethingWrong": "Упс... что-то пошло не так",
    "ALL": "Все статусы",
    "NEW": "Новый",
    "READY_FOR_PICKUP": "Готов к забору",
    "PICKUP_COURIER_ASSIGNED": "Назначен забор на курьера",
    "ON_WAY_TO_WAREHOUSE": "В пути на склад",
    "DELIVERED_TO_WAREHOUSE": "Доставлен на склад",
    "ACCEPTED_BY_WAREHOUSE": "Принят складом по количеству",
    "MARKING_AT_WAREHOUSE": "Маркировка",
    "MARKED_AT_WAREHOUSE": "Отмаркирован",
    "SORTING_AT_WAREHOUSE": "Сортировка",
    "SORTED_AT_WAREHOUSE": "Отсортирован складом",
    "READY_FOR_DELIVERY": "Готов к доставке",
    "READY_FOR_DEPARTURE_DELIVERY": "Готов к доставке [межгород]",
    "DELIVERY_TO_DEPARTURE": "Доставка в пункт отправки [межгород]",
    "DELIVERY_IN_TRANSIT": "В пути [межгород]",
    "READY_FOR_ARRIVAL_DELIVERY": "Готов к забору [межгород]",
    "PICKUP_COURIER_ARRIVAL_ASSIGNED": "Курьер в пути за забором [межгород]",
    "DELIVERY_COURIER_ARRIVAL_ASSIGNED": "Назначен курьер на доставку [межгород]",
    "DELIVERY_COURIER_ASSIGNED": "Назначена доставка на курьера",
    "DELIVERY_IN_PROGRESS": "В пути на доставку",
    "DELIVERED_BY_COURIER": "Доставлен курьером (Завершен)",
    "DELIVERED_ACCEPTED_MANAGER": "Доставка подтверждена менеджером (Завершен)",
    "PENDING_CLIENT_CLARIFICATION": "Ожидает уточнения у Клиента",
    "WAREHOUSE_DELIVERY_RETRY_ON_COURIER_FAULT": "Не успел",
    "WAREHOUSE_DELIVERY_RETRY_ON_CLIENT_UNAVAILABLE": "Нет клиента на месте",
    "WAREHOUSE_DELIVERY_RETRY_ON_INVALID_ADDRESS": "Неверный адрес",
    "UNABLE_TO_DELIVER": "Невозможно доставить (Завершено)",
    "WAREHOUSE_DELIVERY_REPEATED": "Отказ от посылки (Завершено)",
    "DELIVERY_DESTROYED": "Уничтожен (Завершено)",
    "DELIVERY_RETURNED_TO_SENDER": "Возвращен отправителю (Завершено)"
  }
};